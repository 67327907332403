@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&family=League+Gothic&display=swap');

body,
html,
#root {
  height: 100vh;
  font: 14px 'Poppins', sans-serif;
}

body {
  font: 14px 'Poppins', sans-serif;
  font-weight: 400 !important;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  -webkit-font-smoothing: subpixel-antialiased;
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  background: rgba(103, 105, 116, 0.08) !important;
}

.page {
  background-image: url(./assets/img/undraw_hiring_re_yk5n.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 120px auto !important;
}

.row-deck > .col,
.row-deck > [class*='col-'] {
  display: flex;
  align-items: stretch;
}

.row-deck > .col .card,
.row-deck > [class*='col-'] .card {
  flex: 1 1 auto;
}

.bg-transparent {
  background: transparent !important;
}

.text-sm {
  font-size: 12px !important;
}
.text-xs {
  font-size: 11px !important;
}
.ant-collapse-content-box {
  padding: 0px !important;
}
.gulu-pattern {
  background: linear-gradient(
      #3d5c64 33.33%,
      #cbd163 33.33%,
      #cbd163 66.66%,
      #a63a55 66.66%
    )
    no-repeat 100% 100% !important;
  height: 40px !important;
  width: 100% !important;
}

.mubs-pattern {
  background: linear-gradient(
      #a63a55 33.33%,
      #cbd163 33.33%,
      #cbd163 66.66%,
      #3d5c64 66.66%
    )
    no-repeat 100% 100% !important;
  height: 22px !important;
  width: 100% !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  z-index: 50 !important;
}

.muk-card-face,
.mubs-card-face {
  position: relative;
  border-radius: 0.75rem;
}


.muk-card-face::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../src/assets/img/mak-card-bg.png') no-repeat 50% 50%;
  background-size: cover;
  border-radius: 0.75rem !important;
  opacity: 0.4 !important;
}

.mubs-card-face::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../src/assets/img/mubs-card-bg.jpeg') no-repeat 50% 50%;
  background-size: cover;
  border-radius: 0.75rem !important;
  opacity: 0.4 !important;
}

.colored-text {
  color: #034b8f !important;
}

.colored-bg {
  background-color: #034b8f !important;
}

.mubs-name-styled {
  font-family: 'League Gothic', sans-serif !important;
}
